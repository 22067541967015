import {tokenDecoder} from '../../helpers/decodeHelpers';
import {login} from '../../api/apiUser';

export const actions = {
    login({commit}, data) {
        return login(data.sendObj).then((response) => {
            const userRoleKey = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
            const userRole = tokenDecoder(response.data.access_token, userRoleKey).toLowerCase();
            commit('setToken', response.data.access_token);
            commit('setIsAuthorized', true);
            if (userRole === 'superAdmin'.toLowerCase()) {
                data.router.push({path: '/admin'});
            } else {
                data.router.push({path: '/'});
            }
        });
    },
    logout({commit}, router) {
        commit('setToken');
        commit('setIsAuthorized', false);
        router.push({path: '/login'});
    },
};
export default {
    data() {
        return {
            clickOutsideListeners: [],
        };
    },
    beforeDestroy() {
        this.clickOutsideListeners.forEach((listener) => {
            document.removeEventListener('click', listener.handler);
        });
    },
    methods: {
        initClickOutsideListener(data) {
            const {key, callback, el} = data;
            const listener = {
                key,
                el,
                callback,
                handler: (e) => {
                    if (!listener.el.contains(e.target)) {
                        this.destroyEventClickOutsideListener(listener.key);
                    }
                    return false;
                },
            };
            if (this.clickOutsideListeners.findIndex((i) => i.key === listener.key) === -1) {
                this.clickOutsideListeners.push(listener);
                document.addEventListener('click', listener.handler);
            }
        },
        destroyEventClickOutsideListener(listenerKey) {
            const listener = this.clickOutsideListeners.find((i) => i.key === listenerKey);
            if (listener) {
                document.removeEventListener('click', listener.handler);
                this.clickOutsideListeners = this.clickOutsideListeners.filter((i) => i.key !== listener.key);
                return listener.callback();
            }
            return false;
        },
    },
};

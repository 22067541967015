import axios from 'axios';
import cookie from '../services/cookie';

const urlApi = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`;
const config = {
    baseURL: process.env.baseURL || `${urlApi}/api` || '',
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use((axiosConfig) => {
    axiosConfig.headers.Authorization = `Bearer ${cookie.get('jwt')}`;
    return axiosConfig;
});

export default {
    httpRequest(url, body = {}, method = 'get', options = {}) {
        return axiosInstance[method](url, body, options);
    },
};

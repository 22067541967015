export default {
    set(key, value, options = {}) {
        options = {
            path: '/',
            ...options
        };
        if (!options.expires) {
            const date = new Date(Date.now() + 5*60*60*1000);
            options.expires = date.toUTCString();
        } else if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }
        let updatedCookie = encodeURIComponent(key) + "=" + encodeURIComponent(value);
        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }
        document.cookie = updatedCookie;
    },
    get(key) {
        /*eslint-disable */
        var matches = document.cookie.match(new RegExp("(?:^|; )" + key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        /*eslint-enable */
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    delete(key) {
        this.set(key, '', {
            'max-age': -1,
        });
    },
}
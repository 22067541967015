import Vue from 'vue';
import VueRouter from 'vue-router';
import cookie from '../services/cookie'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'discounts',
        component: () => import('../views/Discounts'),
        meta: {
            isRequireLogin: true,
        },
    },
    {
        path: '/admin',
        name: 'admins',
        component: () => import('../views/Admins'),
        meta: {
            isRequireLogin: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login'),
        meta: {
            isRequireLogin: false,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.isRequireLogin && !cookie.get('jwt')) {
        next({path: '/login'});
    } else if (!to.meta.isRequireLogin && cookie.get('jwt')) {
        next({path: '/'});
    } else {
        next();
    }
});

export default router;
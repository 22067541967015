export const tokenDecoder = (token, key) => {
    let targetValue = null;
    if (token) {
        const indexOfFirstDot = token.indexOf('.');
        const indexOfSecondDot = token.indexOf('.', indexOfFirstDot + 1);
        const encodedData = token.slice(indexOfFirstDot + 1, indexOfSecondDot);
        const decodedData = JSON.parse(atob(encodedData));
        targetValue = decodedData[key];
    }
    return targetValue;
};
<template>
    <div id="app">
        <router-view/>
        <VNotification
            v-if="isShowedNotification"
            :notification="notification"
            @close="closeNotification"
        />
    </div>
</template>

<script>
    import {eventBus} from './main';
    import VNotification from './components/VNotification';
    export default {
        name: 'App',
        components: {
            VNotification,
        },
        data() {
            return {
                notification: {
                    contentText: '',
                },
                isShowedNotification: false,
            };
        },
        mounted() {
            this.addEventListeners();
        },
        beforeDestroy() {
            this.removeEventListeners();
        },
        methods: {
            initNotification(data) {
                this.notification = Object.assign(this.notification, data);
                this.isShowedNotification = true;
            },
            addEventListeners() {
                eventBus.$on('initNotification', this.initNotification);
            },
            removeEventListeners() {
                eventBus.$off('initNotification', this.initNotification);
            },
            closeNotification() {
                const clearedNotification = {
                    contentText: '',
                }
                this.notification = Object.assign(this.notification, clearedNotification);
                this.isShowedNotification = false;
            },
        },
    };
</script>

<style lang="scss">
    @import "styles/colors.scss";
    
    #app {
        display: grid;
        font-family: 'Nunito', sans-serif;
        text-align: center;
        color: $greyDark;
    }
    
    body {
        margin: 0;
    }
    
    a {
        text-decoration: none;
    }
</style>

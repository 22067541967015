<template>
    <div class="VNotification">
        <div class="VNotification__background"></div>
        <div
            ref="notificationWrapper"
            class="VNotification__wrapper wrapper"
        >
            <div
                class="button-close"
                @click="closeNotification"
            ></div>
            <div class="wrapper__content content">
                <div class="content__icon"></div>
                <div class="content__text">
                    {{ notification.contentText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import clickOutside from '../mixins/clickOutside';
    
    export default {
        name: 'VNotification',
        mixins: [
            clickOutside,
        ],
        props: {
            notification: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },
        mounted() {
            this.initClickOutsideListener({
                key: 'vNotification',
                el: this.$refs.notificationWrapper,
                callback: () => {
                    this.closeNotification();
                },
            });
        },
        beforeDestroy() {
            this.destroyEventClickOutsideListener('vNotification');
        },
        methods: {
            closeNotification() {
                this.$emit('close');
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .VNotification {
        position: fixed;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
        pointer-events: all;
        display: flex;
        justify-content: center;
        
        &__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $greyDark;
            opacity: .3;
        }
        
        .wrapper {
            position: relative;
            margin-top: 20px;
            z-index: 10;
            display: flex;
            flex-direction: column;
            min-width: 529px;
            background-color: $white;
            height: fit-content;
            border-radius: 10px;
            padding: 44px;
            box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    
            .button-close {
                cursor: pointer;
                position: absolute;
                top: 38px;
                right: 38px;
                width: 12px;
                height: 12px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("../assets/close.svg");
                transition: all .3s;
        
                &:hover {
                    transform: rotate(0.5turn);
                }
            }
            .content {
                display: flex;
                align-items: center;
                &__icon {
                    width: 56px;
                    height: 56px;
                    margin-right: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("../assets/notification-icon_positive.svg");
                }
                &__text {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    width: 100%;
                    color: $greyDark;
                }
            }
        }
    }
</style>
import cookie from '../../services/cookie'

export const mutations = {
    setToken(state, jwt = '') {
        state.jwt = jwt;
        if (jwt) {
            cookie.set('jwt', jwt);
        } else {
            cookie.delete('jwt');
        }
    },
    setIsAuthorized(state, isAuthorized) {
        state.isAuthorized = isAuthorized;
    },
};
